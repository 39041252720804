@font-face {
font-family: '__montserrat_ca2119';
src: url(/_next/static/media/f595c38f5aaaeca5-s.p.ttf) format('truetype');
font-display: swap;
}

@font-face {
font-family: '__montserrat_ca2119';
src: url(/_next/static/media/7603bae74a0800ca-s.p.ttf) format('truetype');
font-display: swap;
font-style: italic;
}@font-face {font-family: '__montserrat_Fallback_ca2119';src: local("Arial");ascent-override: 87.81%;descent-override: 22.77%;line-gap-override: 0.00%;size-adjust: 110.23%
}.__className_ca2119 {font-family: '__montserrat_ca2119', '__montserrat_Fallback_ca2119'
}

